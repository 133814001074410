<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/situacoes-ocorrencia" />
      </v-col>
    </v-row>

    <situacoes-ocorrencia-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import situacoesOcorrencia from '@/api/situacoes-ocorrencia'

  export default {
    name: 'SituacoesOcorrenciaAdicionar',

    components: {
      SituacoesOcorrenciaCampos: () => import('./SituacoesOcorrenciaCampos'),
    },

    data () {
      return {
        carregando: false,
        validacaoFormulario: {},
      }
    },

    methods: {
      async salvar (situacao) {
        this.carregando = true
        this.validacaoFormulario = {}

        try {
          await situacoesOcorrencia.inserir(situacao)

          this.$router.push('/situacoes-ocorrencia', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },
    },
  }
</script>
